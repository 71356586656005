import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { AuthService } from '../providers/local/auth/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderInterceptor implements HttpInterceptor {
  /**
   * @param authService authService
   */
  constructor(private authService: AuthService) {
    this.authService = authService;
  }
/**
 * @param req req
 * @param next next
 * @returns return
 */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request: HttpRequest<any> = req;

    if (req.url.includes('/datos-comunes') && !window.location.href.includes('registration')) {
      request = req.clone({
        setHeaders: {
          'x-user': `${this.authService.getUserParam('userName')}` +
                    `${this.authService.getUserParam('userSurname')}`
        }
      });
    }
    return next.handle(request);
  }
}
