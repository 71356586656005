import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  /**
   * constructor
   */
  constructor() {}
/**
 * @param req req
 * @param next next
 * @returns return
 */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token: string;

    if (req.url.indexOf('/isos/datos-comunes') !== -1) {
      token = localStorage.getItem('X-TOKEN-DatosComunes');
    } else if (req.url.indexOf('/identidad-digital') !== -1) {
      token = localStorage.getItem('X-TOKEN-IdentidadDigital');
    } else {}

    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(req);
  }
}
