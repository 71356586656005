export interface KeycloakUserData {
  username: string;
  lastName: string;
  firstName: string;
  emailVerified: boolean;
  email: string;
  attributes: {
    LDAP_ENTRY_DN: string[];
    LDAP_ID: string[];
    company: string[];
    createTimestamp: string[];
    fullName: string[];
    identificationNumber: string[];
    modifyTimestamp: string[];
    userType: string[];
  };
}

export interface UserData {
  name: string;
  profile: string;
  initials: string;
  color: string;
}

export enum UserType {
  TM = 'Tomador',
  AS = 'Asegurado',
  BE = 'Beneficiario',
  AD = 'Administrador'
}

export const USER_DATA_MAP_USER_DATA = {
  'isos.usuario': 'user',
  nombre: 'name',
  primerApellido: 'surname1',
  segundoApellido: 'surname2',
  identidad: 'id',
  'isos.tipoDocumentoIdentidad': 'docType'
};

export interface PersonalUserData {
  user: string;
  name: string;
  surname1: string;
  surname2: string;
  docType: string;
  id: string;
}

export const USER_DATA_DOCUMENT_TYPE_OPTIONS = [
  { label: 'DNI', value: 'DNI' },
  { label: 'NIF', value: 'NIF' },
  { label: 'NIE', value: 'NIE' },
  { label: 'Pasaporte', value: 'Pasaporte' }
];
