/**
 * iSOS Datos Comunes
 * La API de datos comunes iSOS proporciona a los desarrolladores un conjunto de operaciones comunes a los asegurados y proveedores
 *
 * OpenAPI spec version: 1.3
 * Contact: apis@santalucia.es
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { DevolverMensaje } from '../../../models/api/datos-comunes/DevolverMensaje';
import { MaestroCertFiltrado } from '../../../models/api/datos-comunes/MaestroCertFiltrado';
import { MaestroEstados } from '../../../models/api/datos-comunes/MaestroEstados';
import { MaestroEstadosCertificados } from '../../../models/api/datos-comunes/MaestroEstadosCertificados';
import { ResMaestros } from '../../../models/api/datos-comunes/ResMaestros';
import { ResMaestrosCita } from '../../../models/api/datos-comunes/ResMaestrosCita';
import { RespuestaServiciosFacturados } from '../../../models/api/datos-comunes/RespuestaServiciosFacturados';
import { ResultadoMaestros } from '../../../models/api/datos-comunes/ResultadoMaestros';
import { TiposIncidencias } from '../../../models/api/datos-comunes/TiposIncidencias';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';


@Injectable()
export class MaestrosService {

    protected basePath = '/isos/datos-comunes';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath + '';
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param - consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Muestra los certificados.
     *
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public maestrosConsultarCertificadosFiltrados(observe?: 'body', reportProgress?: boolean): Observable<MaestroCertFiltrado>;
    public maestrosConsultarCertificadosFiltrados(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MaestroCertFiltrado>>;
    public maestrosConsultarCertificadosFiltrados(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MaestroCertFiltrado>>;
    public maestrosConsultarCertificadosFiltrados(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MaestroCertFiltrado>(`${this.basePath}/maestros/v1/certificados`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Muestra los Tipos de estados.
     *
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public maestrosConsultarEstados(observe?: 'body', reportProgress?: boolean): Observable<Array<MaestroEstados>>;
    public maestrosConsultarEstados(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MaestroEstados>>>;
    public maestrosConsultarEstados(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MaestroEstados>>>;
    public maestrosConsultarEstados(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MaestroEstados>>(`${this.basePath}/maestros/v1/estados`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Muestra los estados de los certificados por idioma.
     *
     * @param - idioma ES,CA,EN o FR
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public maestrosConsultarEstadosCertificados(idioma: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MaestroEstadosCertificados>>;
    public maestrosConsultarEstadosCertificados(idioma: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MaestroEstadosCertificados>>>;
    public maestrosConsultarEstadosCertificados(idioma: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MaestroEstadosCertificados>>>;
    public maestrosConsultarEstadosCertificados(idioma: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idioma === null || idioma === undefined) {
            throw new Error('Required parameter idioma was null or undefined when calling maestrosConsultarEstadosCertificados.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MaestroEstadosCertificados>>(`${this.basePath}/maestros/v1/estados-certificados/${encodeURIComponent(String(idioma))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Muestra los Tipos de estados de consulta por idioma.
     *
     * @param - idioma
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public maestrosConsultarEstadosConsulta(idioma: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MaestroEstadosCertificados>>;
    public maestrosConsultarEstadosConsulta(idioma: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MaestroEstadosCertificados>>>;
    public maestrosConsultarEstadosConsulta(idioma: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MaestroEstadosCertificados>>>;
    public maestrosConsultarEstadosConsulta(idioma: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idioma === null || idioma === undefined) {
            throw new Error('Required parameter idioma was null or undefined when calling maestrosConsultarEstadosConsulta.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MaestroEstadosCertificados>>(`${this.basePath}/maestros/v1/estados-consultas/${encodeURIComponent(String(idioma))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Muestra los motivos de cancelación.
     *
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public maestrosConsultarMotivosCancelacion(observe?: 'body', reportProgress?: boolean): Observable<ResultadoMaestros>;
    public maestrosConsultarMotivosCancelacion(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResultadoMaestros>>;
    public maestrosConsultarMotivosCancelacion(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResultadoMaestros>>;
    public maestrosConsultarMotivosCancelacion(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ResultadoMaestros>(`${this.basePath}/maestros/v1/motivos-cancelaciones`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Muestra los resultados de la cita.
     *
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public maestrosConsultarResultadoCita(observe?: 'body', reportProgress?: boolean): Observable<Array<ResMaestrosCita>>;
    public maestrosConsultarResultadoCita(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResMaestrosCita>>>;
    public maestrosConsultarResultadoCita(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResMaestrosCita>>>;
    public maestrosConsultarResultadoCita(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ResMaestrosCita>>(`${this.basePath}/maestros/v1/resultados-citas`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Muestra los servicios facturados.
     *
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public maestrosConsultarServiciosFacturados(observe?: 'body', reportProgress?: boolean): Observable<RespuestaServiciosFacturados>;
    public maestrosConsultarServiciosFacturados(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RespuestaServiciosFacturados>>;
    public maestrosConsultarServiciosFacturados(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RespuestaServiciosFacturados>>;
    public maestrosConsultarServiciosFacturados(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RespuestaServiciosFacturados>(`${this.basePath}/maestros/v1/servicios-facturados`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Muestra los Tipos de incidencias.
     *
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public maestrosConsultarTiposIncidencias(observe?: 'body', reportProgress?: boolean): Observable<Array<TiposIncidencias>>;
    public maestrosConsultarTiposIncidencias(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TiposIncidencias>>>;
    public maestrosConsultarTiposIncidencias(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TiposIncidencias>>>;
    public maestrosConsultarTiposIncidencias(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TiposIncidencias>>(`${this.basePath}/maestros/v1/tipos-incidencias`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Muestra los estados civiles.
     *
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public maestrosEstadoCivil(observe?: 'body', reportProgress?: boolean): Observable<ResMaestros>;
    public maestrosEstadoCivil(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResMaestros>>;
    public maestrosEstadoCivil(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResMaestros>>;
    public maestrosEstadoCivil(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ResMaestros>(`${this.basePath}/maestros/v1/estados-civiles`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Muestra las localidades por provincia.
     *
     * @param - provincia
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public maestrosLocalidades(provincia: string, observe?: 'body', reportProgress?: boolean): Observable<ResMaestros>;
    public maestrosLocalidades(provincia: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResMaestros>>;
    public maestrosLocalidades(provincia: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResMaestros>>;
    public maestrosLocalidades(provincia: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (provincia === null || provincia === undefined) {
            throw new Error('Required parameter provincia was null or undefined when calling maestrosLocalidades.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ResMaestros>(`${this.basePath}/maestros/v1/localidades/${encodeURIComponent(String(provincia))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Maestro de los paises.
     *
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public maestrosPaises(observe?: 'body', reportProgress?: boolean): Observable<ResMaestros>;
    public maestrosPaises(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResMaestros>>;
    public maestrosPaises(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResMaestros>>;
    public maestrosPaises(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ResMaestros>(`${this.basePath}/maestros/v1/paises`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Muestra las provincias.
     *
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public maestrosProvincias(observe?: 'body', reportProgress?: boolean): Observable<ResMaestros>;
    public maestrosProvincias(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResMaestros>>;
    public maestrosProvincias(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResMaestros>>;
    public maestrosProvincias(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ResMaestros>(`${this.basePath}/maestros/v1/provincias`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Muestra las relaciones.
     *
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public maestrosRelacion(observe?: 'body', reportProgress?: boolean): Observable<ResMaestros>;
    public maestrosRelacion(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResMaestros>>;
    public maestrosRelacion(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResMaestros>>;
    public maestrosRelacion(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ResMaestros>(`${this.basePath}/maestros/v1/relaciones`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Muestra las relaciones de fallecidos.
     *
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public maestrosRelacionFallecido(observe?: 'body', reportProgress?: boolean): Observable<ResMaestros>;
    public maestrosRelacionFallecido(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResMaestros>>;
    public maestrosRelacionFallecido(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResMaestros>>;
    public maestrosRelacionFallecido(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ResMaestros>(`${this.basePath}/maestros/v1/relaciones-fallecidos`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Muestra los tipos de personas.
     *
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public maestrosTipoPersona(observe?: 'body', reportProgress?: boolean): Observable<ResMaestros>;
    public maestrosTipoPersona(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResMaestros>>;
    public maestrosTipoPersona(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResMaestros>>;
    public maestrosTipoPersona(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ResMaestros>(`${this.basePath}/maestros/v1/tipos-personas`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
