/**
 * iSOS Datos Comunes
 * La API de datos comunes iSOS proporciona a los desarrolladores un conjunto de operaciones comunes a los asegurados y proveedores
 *
 * OpenAPI spec version: 1.3
 * Contact: apis@santalucia.es
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { DevolverMensaje } from '../../../models/api/datos-comunes/DevolverMensaje';
import { IdentidadActualizar } from '../../../models/api/datos-comunes/IdentidadActualizar';
import { Identidades } from '../../../models/api/datos-comunes/Identidades';
import { IdentidadesSalida } from '../../../models/api/datos-comunes/IdentidadesSalida';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';


@Injectable()
export class IdentidadDigitalSOSService {

    protected basePath = '/isos/datos-comunes';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath + '';
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param - consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Actuliza un usuario en el sistema.
     *
     * @param - identificacion Documento de identidad.
     * @param - identidad Parametros para crear una identidad.
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public identidadDigitalSOSActualizarIdentidades(identificacion: string, identidad: IdentidadActualizar, observe?: 'body', reportProgress?: boolean): Observable<DevolverMensaje>;
    public identidadDigitalSOSActualizarIdentidades(identificacion: string, identidad: IdentidadActualizar, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DevolverMensaje>>;
    public identidadDigitalSOSActualizarIdentidades(identificacion: string, identidad: IdentidadActualizar, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DevolverMensaje>>;
    public identidadDigitalSOSActualizarIdentidades(identificacion: string, identidad: IdentidadActualizar, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identificacion === null || identificacion === undefined) {
            throw new Error('Required parameter identificacion was null or undefined when calling identidadDigitalSOSActualizarIdentidades.');
        }

        if (identidad === null || identidad === undefined) {
            throw new Error('Required parameter identidad was null or undefined when calling identidadDigitalSOSActualizarIdentidades.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<DevolverMensaje>(`${this.basePath}/identidades/v1/usuarios/${encodeURIComponent(String(identificacion))}`,
            identidad,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Creación de un usuario en el sistema.
     *
     * @param - identidad Parametros para crear una identidad.
     * @param - identificacion
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public identidadDigitalSOSCrearIdentidades(identidad: Identidades, identificacion: string, observe?: 'body', reportProgress?: boolean): Observable<IdentidadesSalida>;
    public identidadDigitalSOSCrearIdentidades(identidad: Identidades, identificacion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IdentidadesSalida>>;
    public identidadDigitalSOSCrearIdentidades(identidad: Identidades, identificacion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IdentidadesSalida>>;
    public identidadDigitalSOSCrearIdentidades(identidad: Identidades, identificacion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identidad === null || identidad === undefined) {
            throw new Error('Required parameter identidad was null or undefined when calling identidadDigitalSOSCrearIdentidades.');
        }

        if (identificacion === null || identificacion === undefined) {
            throw new Error('Required parameter identificacion was null or undefined when calling identidadDigitalSOSCrearIdentidades.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<IdentidadesSalida>(`${this.basePath}/identidades/v1/usuarios/${encodeURIComponent(String(identificacion))}`,
            identidad,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Eliminación de un usuario en el sistema.
     *
     * @param - identificacion Documento de identidad.
     * @param - idAplicacion Identificador de la aplicación a eliminar GD,AJT,DJ...
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public identidadDigitalSOSEliminarIdentidades(identificacion: string, idAplicacion: string, observe?: 'body', reportProgress?: boolean): Observable<DevolverMensaje>;
    public identidadDigitalSOSEliminarIdentidades(identificacion: string, idAplicacion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DevolverMensaje>>;
    public identidadDigitalSOSEliminarIdentidades(identificacion: string, idAplicacion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DevolverMensaje>>;
    public identidadDigitalSOSEliminarIdentidades(identificacion: string, idAplicacion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identificacion === null || identificacion === undefined) {
            throw new Error('Required parameter identificacion was null or undefined when calling identidadDigitalSOSEliminarIdentidades.');
        }

        if (idAplicacion === null || idAplicacion === undefined) {
            throw new Error('Required parameter idAplicacion was null or undefined when calling identidadDigitalSOSEliminarIdentidades.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idAplicacion !== undefined && idAplicacion !== null) {
            queryParameters = queryParameters.set('idAplicacion', idAplicacion as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<DevolverMensaje>(`${this.basePath}/identidades/v1/usuarios/${encodeURIComponent(String(identificacion))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Devuelve los datos de un usuario.
     *
     * @param - identificacion Documento de identidad.
     * @param - texto Texto a desencriptar.
     * @param - observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param - reportProgress flag to report request and response progress.
     */
    public identidadDigitalSOSRecuperarIdentidades(identificacion: string, texto: string, observe?: 'body', reportProgress?: boolean): Observable<Identidades>;
    public identidadDigitalSOSRecuperarIdentidades(identificacion: string, texto: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Identidades>>;
    public identidadDigitalSOSRecuperarIdentidades(identificacion: string, texto: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Identidades>>;
    public identidadDigitalSOSRecuperarIdentidades(identificacion: string, texto: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identificacion === null || identificacion === undefined) {
            throw new Error('Required parameter identificacion was null or undefined when calling identidadDigitalSOSRecuperarIdentidades.');
        }

        if (texto === null || texto === undefined) {
            throw new Error('Required parameter texto was null or undefined when calling identidadDigitalSOSRecuperarIdentidades.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (texto !== undefined && texto !== null) {
            queryParameters = queryParameters.set('texto', texto as any);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Identidades>(`${this.basePath}/identidades/v1/usuarios/${encodeURIComponent(String(identificacion))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
